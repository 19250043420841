<template>
  <div class="heading">
    <div class="heading__nav">
      <span class="heading__intro">
        {{ header }}
      </span>
      <div class="heading__content">
        <span>{{ $t("general.questions.progress") }}</span>
        <span class="heading__content--number">
          {{ currentQuestionNumber }}/{{ questionsCount }}
        </span>
      </div>
    </div>
    <h3
      class="heading__title"
      v-html="sanitizeHtml(question, ['strong', 'ul', 'ol', 'li', 'br'])"
    />
  </div>
</template>
<script>
import sanitize from '@/common/sanitize/sanitizeHtml';

export default {
  name: 'Heading',
  props: {
    question: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      default: '',
    },
    questionsCount: {
      type: Number,
      required: true,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
  },
  methods: {
    ...sanitize,
  },
};
</script>
<style lang="scss" scoped>
.heading {
  position: relative;
  &__nav {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 2rem;
    @include media-min($md) {
      flex-direction: row;
    }
  }
  &__intro {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.8rem;
    color: var(--primary-color);
  }
  &__content {
    display: flex;
    align-items: flex-start;
    padding: 1rem 0 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $gray400;
    @include media-min($md) {
      padding: 0;
      margin: 0;
      border: none;
    }
    &--number {
      display: block;
      background: $gray400;
      padding: 0.2rem 1rem;
      border-radius: 4px;
      margin-left: 1rem;
    }
  }
  &__title {
    color: $black;
    font-size: 1.6rem;
    line-height: 2.8rem;
    margin: 1.5rem 0 1rem;
    @include media-min($md) {
      margin: 2.8rem 0;
    }
  }
}
</style>
