<template>
  <div class="question">
    <div class="container__main-photo">
      <BaseImage
        :src="stage.sideImageUrl ? stage.sideImageUrl : require(`@/assets/images/sideImages/default.jpg`)"
        :name="$t('game.name')"
        lazy-mode
      />
    </div>
    <div class="container">
      <div class="container__wrapper">
        <Heading
          :header="stage.header"
          :question="stage.body"
          :questions-count="questionsCount"
          :current-question-number="currentQuestionNumber"
        />
        <div
          v-if="stage.imageUrl"
          class="question-image container__gallery"
        >
          <QuestionImage :image="stage.imageUrl" />
        </div>
        <slot />
        <div class="container__main-button question__buttons">
          <BaseButton
            v-if="skipEnabled"
            alternative
            @click="skipStep"
          >
            {{ $t('game.skip') }}
          </BaseButton>
          <BaseButton
            :disabled="!hasSelectedAnswer"
            :is-loading="isSending"
            @click="answered"
          >
            {{ $t('general.questions.accept') }}
          </BaseButton>
        </div>
      </div>
    </div>
    <Feedback
      v-if="feedback !== null && showFeedback"
      :feedback="feedback"
      :is-loading="isLoading"
      @continue="nextStep"
    />
    <Badge
      v-if="badge !== null && showBadge"
      :badge="badge"
      :question="stage"
      :is-loading="isLoading"
      @show-all-badges="completedShowBadges"
      @continue="completed"
      @show-video="handleShowVideo"
    />
    <ModalPlayer
      v-if="showBageVideoModal"
      :badge="activeBadge"
      @close-video="handleCloseVideo"
    />
  </div>
</template>
<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import eventBus from '@/common/eventBus';
import alerts from '@/common/alerts/alerts';
import GameQuestion from '@/domain/entities/flow/GameQuestion';
import GameRecord from '@/domain/entities/flow/GameRecord';
import QuestionImage from '@/components/Stage/Partials/Image';
import BaseImage from '@/components/Image/BaseImage';
import Heading from '@/components/Stage/Partials/Heading';
import BaseButton from '@/components/Form/BaseButton';
import Feedback from '@/components/Feedback/Feedback';
import Badge from '@/components/Badge/Badge';
import ModalPlayer from '@/components/Video/ModalPlayer';
import { HTTP_UNAUTHORIZED, HTTP_UNPROCESSABLE_ENTITY } from '@/common/axios/codes';
import { START_COUNTDOWN, PAUSE_COUNTDOWN } from '@/names/events.names';

export default {
  name: 'GameStage',
  components: {
    Badge,
    QuestionImage,
    Feedback,
    BaseImage,
    Heading,
    BaseButton,
    ModalPlayer,
  },
  props: {
    stage: {
      type: [GameQuestion, GameRecord],
      required: true,
    },
    feedback: {
      type: [Object],
      default: null,
    },
    badge: {
      type: [Object],
      default: null,
    },
    hasSelectedAnswer: {
      type: Boolean,
      required: true,
    },
    questionsCount: {
      type: Number,
      required: true,
    },
    currentQuestionNumber: {
      type: Number,
      required: true,
    },
    hasSessionData: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    skipEnabled: {
      type: Boolean,
      default: false,
    },
    recordedData: {
      type: [Blob],
      default: null,
    },
  },
  data() {
    return {
      showBadge: false,
      showFeedback: false,
      isSending: false,
      sideImage: null,
      activeBadge: null,
      showBageVideoModal: false,
      cancelTokenSource: null,
    };
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
    ...mapGetters('session', ['hasGameTimeLimit']),
  },
  watch: {
    $route() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Operation canceled due to navigation change.');
        eventBus.$emit(START_COUNTDOWN);
      }
    },
  },
  beforeDestroy() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled due to component destruction.');
      eventBus.$emit(START_COUNTDOWN);
    }
  },
  created() {
    this.hasSessionData && this.nextStep();
  },
  methods: {
    ...mapActions('flow', ['markQuestionAsAnswered', 'markStageBadgeAsCompleted', 'uploadRecordedData', 'stopGameTime']),
    ...mapActions('layout', ['resetProgress']),
    ...alerts,
    async answered() {
      if (this.isSending) {
        return;
      }

      this.isSending = true;

      try {
        if (this.recordedData) {
          if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('Operation canceled due to new request.');
          }
          this.cancelTokenSource = axios.CancelToken.source();
          if (this.hasGameTimeLimit) {
            await this.stopGameTime();
            eventBus.$emit(PAUSE_COUNTDOWN);
          }
          await this.uploadRecordedData({ data: this.gameRecordFormData(), cancelToken: this.cancelTokenSource.token });
          this.nextStep();

          return;
        }

        await this.markQuestionAsAnswered(this.stage);
        this.nextStep();
      } catch (e) {
        if (e.response?.status === HTTP_UNAUTHORIZED) {
          return;
        }
        if (e.response?.status === HTTP_UNPROCESSABLE_ENTITY) {
          const answersError = e.response?.data?.errors?.answers;
          const recordError = e.response?.data?.errors?.record;
          if (recordError && recordError.includes('Record for this step has already been added earlier')) {
            this.nextStep();

            return;
          }
          if (answersError && answersError.includes('This question has already been answered')) {
            this.warningAlert({
              html: this.$t('general.questions.question_has_already_been_answered'),
            });
          }

          return;
        }
        this.warningAlert();
        throw new Error(JSON.stringify('meta' in (e.response?.data || {}) ? (({ meta, ...errors }) => errors)(e.response.data) : e.response?.data));
      } finally {
        this.isSending = false;
      }
    },
    gameRecordFormData() {
      const formData = new FormData();
      formData.set('record_id', this.stage.recordId);
      const blob = new Blob([this.recordedData], { type: 'video/mp4' });
      formData.set('record', blob, 'filename.mp4');

      return formData;
    },
    async skipStep() {
      const { isDismissed } = await this.skipStageAlert(!!this.recordedData);
      if (isDismissed) return;
      this.nextStep();
    },
    nextStep() {
      if (this.feedback !== null && this.showFeedback === false) {
        this.showFeedback = true;

        return;
      }

      if (this.badge !== null) {
        this.showFeedback = false;
        this.markStageBadgeAsCompleted(this.stage);
        this.showBadge = true;

        return;
      }

      this.completed();
    },
    completedShowBadges() {
      this.resetProgress();
      this.$emit('completed-show-badges');
    },
    completed() {
      this.resetProgress();
      this.$emit('completed');
    },
    handleShowVideo(badge) {
      this.showBageVideoModal = true;
      this.activeBadge = badge;
    },
    handleCloseVideo() {
      this.showBageVideoModal = false;
      this.activeBadge = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.question {
  position: relative;
  &__main-photo {
    position: absolute;
    display: none;
    width: 23%;
    bottom: 0;
    left: 0;
    height: calc(100vh - 100px);
    height: calc(100svh - 100px);
    object-fit: cover;
    @include media-min($lg) {
      height: calc(100vh - 95px);
      height: calc(100svh - 95px);
      display: block;
    }
  }
  &__content-wrapper {
    max-width: 1220px;
    max-height: calc(100vh - 100px - 0.75rem);
    max-height: calc(100svh - 100px - 0.75rem);
    padding: 1.5rem;
    @include media-min($lg) {
      max-height: calc(100vh - 95px - 10rem);
      max-height: calc(100svh - 95px - 10rem);
      padding: 0 5rem 5rem 0;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column-reverse;
    gap: 2rem;
    @include media-min($sm) {
      flex-direction: row;
    }
  }
}
</style>
