<template>
  <div>
    <div class="image">
      <div
        v-for="(src, index) in images"
        :key="`image-${index}`"
        class="image__item"
        @click="showImage(index)"
      >
        <img :src="src">
        <div class="image__overlay">
          <div class="image__icon">
            <Icon
              name="eye"
              :color="variables.white"
            />
          </div>
        </div>
      </div>
    </div>
    <BaseGallery
      :visible="visible"
      :imgs="images"
      :index="currentImage"
      @hide="hideImage"
    />
  </div>
</template>
<script>
import Icon from '@/components/Icon/Icon';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'QuestionImage',
  components: {
    Icon,
  },
  props: {
    image: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variables,
      visible: false,
      currentImage: 0,
      images: [this.image],
    };
  },
  methods: {
    showImage(index) {
      this.currentImage = index;
      this.visible = true;
    },
    hideImage() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vel-modal {
  z-index: 99999 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  @include blur-background();
}
::v-deep .vel-img {
  box-shadow: none !important;
}
.image {
  display: flex;
  &__item {
    position: relative;
    width: 12.4rem;
    height: 12.4rem;
    margin: 0 2rem 0 0;
    cursor: pointer;
    border: 2px solid $gray400;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.4);
      transition: 0.5s ease-in;
    }
    &:hover > img {
      opacity: 0.6;
      transform: scale(1.05);
    }
    &:hover > .image__overlay {
      opacity: 1;
    }
    &:hover .image__icon {
      transform: scale(1);
      transition: all 0.6s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
  }
  &__overlay {
    background-color: var(--light-primary-color);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.3s 0.1s ease-in;
  }
  &__icon {
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
}
</style>
