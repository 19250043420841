<template>
  <FadeIn appear>
    <div class="feedback">
      <div class="feedback__content">
        <div
          class="feedback__image"
          :style="{ 'background-image': `url('${feedback.imageUrl}')` }"
        />
        <p class="feedback__text">
          {{ feedback.body }}
        </p>
        <BaseButton
          :is-loading="isLoading"
          @click="callback"
        >
          {{ $t('game.continue') }}
        </BaseButton>
      </div>
    </div>
  </FadeIn>
</template>
<script>
import BaseButton from '@/components/Form/BaseButton';
import Feedback from '@/domain/entities/flow/Feedback';
import FadeIn from '@/components/Transition/FadeInTransition';

export default {
  name: 'Feedback',
  components: {
    BaseButton,
    FadeIn,
  },
  props: {
    feedback: {
      type: Feedback,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    callback() {
      this.$emit('continue');
    },
  },
};
</script>
<style lang="scss" scoped>
.feedback {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  z-index: 9997;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  background: $white;
  @include media-min($lg) {
    @include blur-background();
    padding: 0 5rem 5rem 0;
  }

  &__content {
    margin-top: 5vh;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    @include media-min($sm) {
      max-width: 80vw;
    }
    @include media-min($lg) {
      margin-top: 10vh;
      max-width: 750px;
    }
  }

  &__image {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background-position: center center;
    background-size: cover;
    @include media-min($md) {
      width: 20rem;
      height: 20rem;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 3.5rem;
    margin: 3rem 0;
    @include media-min($md) {
      font-size: 3.4rem;
      line-height: 4.8rem;
      margin: 3.6rem 0;
    }
  }
}
</style>
