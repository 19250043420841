<template>
  <FadeIn appear>
    <Loader
      v-if="isLoading"
      :is-component-loader="true"
    />
    <div
      v-else
      class="badge__background"
    >
      <div class="badge">
        <div class="badge__container">
          <div class="badge__wrapper">
            <div
              v-if="badge.isVideoBadge"
              class="badge__video"
              @click="showVideo"
            >
              <div
                v-show="showBadgeImage"
                class="badge__icon"
                :style="{ backgroundImage: `url(${badge.imageUrl})` }"
              />
              <video
                ref="previewVideo"
                loop
                muted
                class="badge__clip"
              >
                <source
                  v-for="(video, index) in badge.videos"
                  :key="index"
                  :type="video.type"
                  :src="video.url"
                >
              </video>
              <div
                v-show="badge.isCompleted && badge.isVideoBadge"
                class="badge__video__overlay"
              >
                <div class="badge__video__play-icon">
                  <Icon
                    name="play-big"
                    :color="variables.white"
                  />
                </div>
              </div>
            </div>
            <div
              v-else
              class="badge__image"
              :style="{ backgroundImage: `url(${badge.imageUrl})` }"
            />
            <div
              v-if="!badge.isCompleted"
              class="badge__lock-icon"
            >
              <Icon
                name="lock"
                :color="variables.white"
              />
            </div>
            <div
              v-if="badge.isVideoBadge && badge.isCompleted"
              class="badge__is-video-icon"
            >
              <Icon
                name="camera"
                :color="variables.white"
              />
            </div>
          </div>
        </div>
        <p class="badge__text">
          {{ $t('general.badges.received') }}: <span>{{ badge.body }}</span>
        </p>
        <p
          v-if="badge.description"
          class="badge__desc"
        >
          {{ badge.description }}
        </p>
        <div class="badge__buttons">
          <BaseButton
            alternative
            @click="badge.isVideoBadge ? showVideo() : showAllBadges()"
          >
            {{ badge.isVideoBadge ? $t('general.badges.show_badge') : $t('general.badges.show_badges') }}
          </BaseButton>
          <BaseButton @click="nextStep">
            {{ $t('general.badges.continue') }}
          </BaseButton>
        </div>
      </div>
    </div>
  </FadeIn>
</template>
<script>
import Loader from '@/components/Loader/Loader';
import BaseButton from '@/components/Form/BaseButton';
import Badge from '@/domain/entities/flow/Badge';
import Icon from '@/components/Icon/Icon';
import FadeIn from '@/components/Transition/FadeInTransition';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'Badge',
  components: {
    Loader,
    BaseButton,
    Icon,
    FadeIn,
  },
  props: {
    badge: {
      type: Badge,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      variables,
      showBadgeImage: true,
    };
  },
  mounted() {
    this.initAutoPlay();
  },
  methods: {
    initAutoPlay() {
      if (!this.badge.isVideoBadge && !this.$refs.previewVideo) return;

      setTimeout(() => {
        this.$refs.previewVideo && this.$refs.previewVideo.play();
        this.showBadgeImage = false;
      }, 1000);
    },
    showAllBadges() {
      this.$emit('show-all-badges');
    },
    nextStep() {
      this.$emit('continue');
    },
    showVideo() {
      if (!this.badge.isVideoBadge) return;

      this.$emit('show-video', this.badge);
    },
  },
};
</script>
<style lang="scss" scoped>
.badge {
  margin-top: 5vh;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  @include media-min($sm) {
    max-width: 80vw;
  }
  @include media-min($lg) {
    margin-top: 10vh;
    max-width: 750px;
  }
  &__background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 100%;
    z-index: 9997;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    background: $white;
    @include media-min($lg) {
      @include blur-background();
      padding: 0 5rem 5rem 0;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
  &__container {
    position: relative;
    width: 15rem;
    height: 15rem;
    @include media-min($md) {
      width: 20rem;
      height: 20rem;
    }
  }
  &__wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid var(--primary-color);
    overflow: hidden;
  }
  &__icon {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
  }
  &__video {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: relative;
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media only screen and (orientation : landscape){
      &:hover > video {
        opacity: 0.8;
      }
      &:hover > .badge__video__overlay {
        opacity: 1;
      }
      &:hover .badge__video__play-icon {
        transform: scale(1);
        transition: all 0.6s 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
    &__overlay {
      background-color: var(--light-primary-color);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in;
    }
    &__play-icon {
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
    }
  }
  &__is-video-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 3.6rem;
    height: 3.6rem;
    background: var(--primary-color);
    border-radius: 50%;
    top: 0.7rem;
    left: 0.7rem;
    @include media-min($md) {
      top: 1.4rem;
      left: 1.4rem;
    }
  }
  &__text {
    font-size: 2.8rem;
    line-height: 3.8rem;
    margin: 3rem 0 2.4rem;
    @include media-min($md) {
      font-size: 3.4rem;
      line-height: 4 rem;
      margin: 3.6rem 0 2.4rem;
    }
    span {
      font-weight: 700;
    }
  }
  &__desc {
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 0 6rem;
    @include media-min($md) {
      margin: 0 0 3.6rem;
    }
  }
  &__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-bottom: 2rem;
    @include media-min($md) {
      flex-direction: row;
    }
    button {
      margin: 0.8rem 0;
      @include media-min($md) {
        margin: 0 1rem;
      }
    }
  }
}
</style>
